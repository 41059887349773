








import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Notification extends Vue {
  @Prop()
  private isEmail: boolean

  @Prop()
  private isEnabled: boolean

  @Prop()
  private subject: string

  @Prop()
  private body: string

  private data = {
    isEnabled: false,
    subject: '',
    body: '',
  }

  private created() {
    this.data.isEnabled = this.isEnabled
    this.data.subject = this.subject
    this.data.body = this.body
  }

  private get isEnabledLabel(): string {
    return this.$t(this.isEmail ? 'Enable e-mail notification' : 'Enable SMS notification')
  }

  @Watch('data', { deep: true })
  private onChangeData() {
    this.$emit('data', this.data)
  }
}
