








































































import Vue from 'vue'
import Component from 'vue-class-component'
import Step1 from '@/components/booking/auto-booking/Step1.vue'
import Step2 from '@/components/booking/auto-booking/Step2.vue'
import Step3 from '@/components/booking/auto-booking/Step3.vue'
import Step4 from '@/components/booking/auto-booking/Step4.vue'
import Step5 from '@/components/booking/auto-booking/Step5.vue'
import Step6 from '@/components/booking/auto-booking/Step6.vue'
import Step7 from '@/components/booking/auto-booking/Step7.vue'
import AutoBooking from '@/models/booking/AutoBooking'
import { vxm } from '@/store'
import AutoBookingMeta from '@/models/booking/AutoBookingMeta'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
  },
})
export default class Edit extends Vue {
  private isLoading = false
  private isReady = false
  private step = 1
  private data: AutoBooking = new AutoBooking()
  private meta: AutoBookingMeta = new AutoBookingMeta()

  public mounted() {
    this.meta.load(this.$axios).then(() => {
      if (this.isNew) {
        this.data = new AutoBooking()
        this.data.sortOrder = 'Standard'
        this.data.customerType = 'All'
        this.data.name = this.meta.defaultName
        if (this.meta.calendars.length === 1) {
          this.data.calendarId = this.meta.calendars[0].id
        }
        this.isReady = true
      } else {
        this.loadAutoBooking().then(() => {
          this.isReady = true
        })
      }
    })
  }

  private get isNew() {
    return !!this.$route.meta.isNew
  }

  @Watch('data.calendarId')
  private onChangedCalendarIdInput(after, before) {
    if (before && before !== after) {
      this.data.portalId = null
      this.data.placeId = null
    }
    console.log('onchanged')
    this.meta.selectCalendar(this.data.calendarId, this.$axios)
  }

  private loadAutoBooking() {
    return new Promise((resolve, reject) => {
      this.$axios
        .get('/v4/site/calendars/0/auto-bookings/' + this.$route.params.id)
        .then((response) => {
          this.data = new AutoBooking(response.data.data)
          this.step = this.data.step
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to load auto-booking')
          reject()
        })
    })
  }

  private setStep(step: number) {
    this.isLoading = true
    this.data.step = step
    this.save()
      .then(() => {
        this.isLoading = false
        this.step = step
      })
      .catch(() => {
        this.isLoading = false
      })
  }

  private save() {
    this.data.maxNumberOfBookings = parseInt('' + this.data.maxNumberOfBookings) || null
    return new Promise((resolve, reject) => {
      if (this.data.id) {
        this.$axios
          .put('/v4/site/calendars/0/auto-bookings/' + this.data.id, this.data)
          .then((_response) => {
            resolve()
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err, 'Failed to save auto-booking')
            reject()
          })
      } else {
        this.$axios
          .post('/v4/site/calendars/0/auto-bookings', this.data)
          .then((response) => {
            this.data.id = response.data.data.id
            this.$router.push({
              name: 'AutoBooking/Edit',
              params: { id: '' + this.data.id },
            })
            resolve()
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err, 'Failed to save auto-booking')
            reject()
          })
      }
    })
  }

  private clickCreateBookings() {
    this.isLoading = true
    this.executeCreateBookings()
      .then(() => {
        this.isLoading = false
        this.step = 7
        this.data.step = 7
      })
      .catch(() => {
        this.isLoading = false
      })
  }

  private executeCreateBookings() {
    return new Promise((resolve, reject) => {
      this.$axios
        .post('/v4/site/calendars/0/auto-bookings/' + this.data.id + '/execute')
        .then((response) => {
          console.log('execute response: ', response)
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to execute create-bookings')
          reject()
        })
    })
  }
}
