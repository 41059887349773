








































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import AutoBooking from '@/models/booking/AutoBooking'
import AutoBookingMeta from '@/models/booking/AutoBookingMeta'
import Notification from '@/components/booking/auto-booking/Notification.vue'
import { Debounce } from 'lodash-decorators'
import { vxm } from '@/store'

@Component({
  components: { Notification },
})
export default class Step4 extends Vue {
  @Prop()
  private data: AutoBooking

  @Prop()
  private meta: AutoBookingMeta

  @Prop()
  private active: boolean

  private contactModes = []
  private error = ''
  private tab = 'email'

  private previewTab = 'preview'
  private preview = {
    subject: '',
    body: '',
  }

  public created(): void {
    this.contactModes = [
      { id: 'None', name: this.$t('c:auto-booking:None - book everything and notify nobody') },
      {
        id: 'Driver',
        name: this.$t('c:auto-booking:Driver - book if driver has email or mobile, and notify only driver'),
      },
      {
        id: 'DriverThenCustomer',
        name: this.$t(
          'c:auto-booking:Driver then customer - book if anybody has email or mobile, and notify the one who has it',
        ),
      },
    ]
  }

  private updateEmail(data: Record<string, unknown>) {
    this.data.emailEnabled = data.isEnabled as boolean
    this.data.emailSubject = data.subject as string
    this.data.emailBody = data.body as string
    this.loadPreview()
  }

  private updateSms(data: Record<string, unknown>) {
    this.data.smsEnabled = data.isEnabled as boolean
    this.data.smsBody = data.body as string
    this.loadPreview()
  }

  private get isTabEmail(): boolean {
    return '' + this.tab === '0'
  }

  @Watch('tab')
  private onTabChange() {
    this.preview.subject = ''
    this.preview.body = ''
    this.loadPreview()
  }

  @Watch('active')
  private onActiveChange() {
    this.loadPreview()
  }

  @Debounce(500)
  private loadPreview() {
    if (!this.active) {
      return
    }
    const subject = this.isTabEmail ? this.data.emailSubject : ''
    const body = this.isTabEmail ? this.data.emailBody : this.data.smsBody
    this.$axios
      .post('/v4/site/calendars/0/auto-bookings/' + this.data.id + '/notification-preview', {
        subject: subject || '',
        body: body || '',
      })
      .then((response) => {
        this.preview.subject = response.data.data.subject || ''
        this.preview.body = response.data.data.body || ''
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load preview')
      })
  }

  private next() {
    this.error = ''
    if (!this.data.contactMode) {
      this.error = 'Must select a contact mode'
      return
    }
    if (this.data.emailEnabled) {
      if (!this.data.emailSubject || !this.data.emailBody) {
        this.error = 'When e-mail is enabled, both subject and body text for email should be set'
        return
      }
    }
    if (this.data.smsEnabled) {
      if (!this.data.smsBody) {
        this.error = 'When SMS is enabled, body text for SMS should be set'
        return
      }
    }
    this.$emit('next')
  }

  private back() {
    this.$emit('back')
  }
}
