































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import AutoBookingMeta from '@/models/booking/AutoBookingMeta'
import Notification from '@/components/booking/auto-booking/Notification.vue'
import { DateTime, Duration } from 'luxon'
import { vxm } from '@/store'

@Component({
  components: { Notification },
})
export default class Step7 extends Vue {
  @Prop()
  private meta: AutoBookingMeta

  @Prop()
  private active: boolean

  private pauseAfterCreateBookings: boolean = null
  private status = ''
  private polling = true
  private error = ''
  private timeout: ReturnType<typeof setTimeout> = null

  private phases = [
    { id: 'Scheduled', status: 'Todo', info: 'Waiting to be executed' },
    { id: 'CreateReservations', status: 'Todo', info: 'Reserving times' },
    { id: 'CreateBookings', status: 'Todo', info: 'Creating bookings' },
    { id: 'SendNotifications', status: 'Todo', info: 'Sending notifications (if appropriate)' },
    { id: 'Completed', status: 'Todo', info: 'Finished' },
  ]

  private setAllPhasesTodo() {
    for (let i = 0; i < this.phases.length; i++) {
      this.phases[i].status = 'Todo'
    }
  }

  private setPhaseStatus(stepId, status) {
    for (let i = 0; i < this.phases.length; i++) {
      if (this.phases[i].id === stepId) {
        this.phases[i].status = status
        return
      }
    }
  }

  private onUpdateStatus(status) {
    this.setAllPhasesTodo()
    switch (this.status) {
      case 'Scheduled':
        this.setPhaseStatus('Scheduled', 'Running')
        break
      case 'CreateReservationsRunning':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Running')
        break
      case 'CreateReservationsSuccess':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Done')
        break
      case 'CreateReservationsError':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Error')
        break
      case 'CreateBookingsRunning':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Done')
        this.setPhaseStatus('CreateBookings', 'Running')
        break
      case 'CreateBookingsError':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Done')
        this.setPhaseStatus('CreateBookings', 'Error')
        break
      case 'CreateBookingsSuccess':
      case 'SendNotificationsScheduled':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Done')
        this.setPhaseStatus('CreateBookings', 'Done')
        break
      case 'SendNotificationsRunning':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Done')
        this.setPhaseStatus('CreateBookings', 'Done')
        this.setPhaseStatus('SendNotifications', 'Running')
        break
      case 'SendNotificationsSuccess':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Done')
        this.setPhaseStatus('CreateBookings', 'Done')
        this.setPhaseStatus('SendNotifications', 'Done')
        break
      case 'SendNotificationsError':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Done')
        this.setPhaseStatus('CreateBookings', 'Done')
        this.setPhaseStatus('SendNotifications', 'Error')
        break
      case 'Completed':
        this.setPhaseStatus('Scheduled', 'Done')
        this.setPhaseStatus('CreateReservations', 'Done')
        this.setPhaseStatus('CreateBookings', 'Done')
        this.setPhaseStatus('SendNotifications', 'Done')
        this.setPhaseStatus('Completed', 'Done')
        break
      default:
        console.error('Unknown status: ' + status)
    }
  }

  public mounted(): void {
    this.pollByInterval(3000)
  }

  public beforeDestroy(): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  private pollByInterval(msec: number) {
    this.timeout = setTimeout(() => {
      this.poll()
        .then(() => {
          if (this.status !== 'Completed') {
            this.pollByInterval(msec)
          }
        })
        .catch(() => {
          if (this.status !== 'Completed') {
            this.pollByInterval(msec)
          }
        })
    }, msec)
  }

  private poll() {
    this.polling = true
    return new Promise((resolve, reject) => {
      if (this.active && this.status !== 'Completed' && this.$route?.params?.id) {
        this.$axios
          .get('/v4/site/calendars/0/auto-bookings/' + this.$route.params.id)
          .then((response) => {
            const oldStatus = this.status + ''
            this.status = response.data.data.status
            this.pauseAfterCreateBookings = response.data.data.pauseAfterCreateBookings
            if (oldStatus !== this.status) {
              this.onUpdateStatus(this.status)
            }
            this.polling = false
            resolve()
          })
          .catch((err) => {
            this.error = 'Failed to poll status info, will retry'
            console.error('Failed to poll status info: ', err.response?.data)
            this.polling = false
            reject()
          })
      } else {
        this.polling = false
        resolve()
      }
    })
  }

  private getNotificationPhaseStatus(): string {
    for (let i = 0; i < this.phases.length; i++) {
      if (this.phases[i].id === 'SendNotifications') {
        return this.phases[i].status
      }
    }
    return ''
  }

  private get notificationsInfoText(): string {
    if (this.pauseAfterCreateBookings === null) {
      return '' // i.e. not fully initialized yet, so do not show anything
    }
    switch (this.getNotificationPhaseStatus()) {
      case 'Running':
        return 'Notifications are being sent'
      case 'Done':
        return 'Notifications have been sent'
      default:
        if (this.status === 'SendNotificationsScheduled') {
          return 'Will soon send notifications'
        }
        if (this.pauseAfterCreateBookings) {
          return 'Will wait before sending notifications'
        } else {
          return 'Will automatically send notifications'
        }
    }
  }

  private get shouldShowSendNotificationsAction(): boolean {
    if (this.pauseAfterCreateBookings === null) {
      return false // i.e. not fully initialized yet, so do not show anything
    }
    switch (this.getNotificationPhaseStatus()) {
      case 'Running':
      case 'Done':
        return false
      default:
        if (this.status === 'SendNotificationsScheduled') {
          return false
        }
        if (this.pauseAfterCreateBookings) {
          return true
        } else {
          return false
        }
    }
  }

  private sendNotifications() {
    if (!confirm(this.$t('Are you sure?'))) {
      return
    }
    this.$axios
      .post('/v4/site/calendars/0/auto-bookings/' + this.$route.params.id + '/unpause-notifications')
      .then((response) => {
        this.status = response.data.data.status as string
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to start sending notifications')
      })
  }

  private getPhaseClass(phase: Record<string, string>) {
    if (!phase.status) {
      return ''
    }
    return 'status-' + phase.status.toLowerCase()
  }

  private getPhaseIcon(phase: Record<string, string>) {
    if (!phase.status) {
      return ''
    }
    switch (phase.status) {
      case 'Running':
        return 'fa fa-spinner fa-spin'
      case 'Todo':
        return 'fa fa-clock'
      case 'Done':
        return 'fa fa-check-circle'
      case 'Error':
        return 'fa fa-exclamation-circle'
    }
    return ''
  }

  private get bookingsLink() {
    const start = DateTime.now().minus(Duration.fromISO('P1Y')).toFormat('yyyy-MM-dd')
    const end = DateTime.now().plus(Duration.fromISO('P1Y')).toFormat('yyyy-MM-dd')
    // http://app.eontyre.local.eonbit.com:8080/707/bookings?sortBy=time&sortDesc=&page=1&perPage=30&search=autoBookingId%3A%3D1&_ts=1647357207566&start=2022-03-15&end=2022-03-15
    return {
      name: 'Booking/List',
      query: {
        sortBy: 'time',
        sortDesc: '',
        search: 'autoBookingId:=' + this.$route.params.id,
        start,
        end,
      },
    }
  }
}
