












































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import AutoBooking from '@/models/booking/AutoBooking'
import AutoBookingMeta from '@/models/booking/AutoBookingMeta'

@Component({
  components: {},
})
export default class Step2 extends Vue {
  @Prop()
  private data: AutoBooking

  @Prop()
  private meta: AutoBookingMeta

  private error = ''
  private hotelProducts = []

  public mounted(): void {
    const hotelProducts = []
    for (let i = 0; i < this.meta.hotelProducts.length; i++) {
      const hotelProduct: Record<string, unknown> = {}
      Object.assign(hotelProduct, this.meta.hotelProducts[i])
      this.detectServiceForHotelProduct(hotelProduct)
      hotelProducts.push(hotelProduct)
    }
    this.hotelProducts = hotelProducts
  }

  private detectServiceForHotelProduct(hotelProduct: Record<string, unknown>): number {
    const hotelProductId = hotelProduct.id as number
    hotelProduct.serviceId = null
    hotelProduct.isEnabled = true
    if (this.data.services && this.data.services.length > 0) {
      // Use mapping previously defined by user on this particular autobooking
      for (let i = 0; i < this.data.services.length; i++) {
        const abs = this.data.services[i]
        if (abs.hotelProductId === hotelProductId) {
          hotelProduct.serviceId = abs.serviceProductId
          hotelProduct.isEnabled = abs.isEnabled
          return
        }
      }
    } else {
      // Use services' own mapping about which hotel products they belong to
      for (let j = 0; j < this.meta.services.length; j++) {
        const s = this.meta.services[j]
        if (s.tyreHotelProductIds) {
          for (let k = 0; k < s.tyreHotelProductIds.length; k++) {
            if (s.tyreHotelProductIds[k] === hotelProductId) {
              hotelProduct.serviceId = s.id
              hotelProduct.isEnabled = true
              return
            }
          }
        }
      }
    }
    // Not defined (yet)
  }

  private copyServiceToAllHotelProducts(serviceId: number) {
    for (let i = 0; i < this.hotelProducts.length; i++) {
      this.hotelProducts[i].serviceId = serviceId
    }
  }

  private getUsedCountText(item: Record<string, unknown>): string {
    const count = '' + item.usedCount || '0'
    let text = this.$t('c:auto-booking-hotel-product:used for %s active hotels')
    if ((item.status as string) === 'Deleted') {
      text += ' - ' + this.$t('DELETED')
    }
    return text.replace('%s', count)
  }

  private reset() {
    this.copyServiceToAllHotelProducts(null)
    this.error = ''
  }

  private next() {
    this.error = ''
    const autoBookingServices = []

    for (let i = 0; i < this.hotelProducts.length; i++) {
      // PS: Will enforce even for those not in use, because that could in theory change before we actually execute auto-booking
      const hasService = !!this.hotelProducts[i].serviceId
      const isEnabled = !!this.hotelProducts[i].isEnabled
      if (!hasService && isEnabled) {
        this.error = this.$t('Must set a booking service for all hotel products that should be booked')
        return
      }
      autoBookingServices.push({
        hotelProductId: this.hotelProducts[i].id,
        serviceProductId: this.hotelProducts[i].serviceId,
        isEnabled: isEnabled,
      })
    }

    this.data.services = autoBookingServices
    this.$emit('next')
  }

  private back() {
    this.error = ''
    this.$emit('back')
  }
}
