






































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import AutoBooking from '@/models/booking/AutoBooking'
import AutoBookingMeta from '@/models/booking/AutoBookingMeta'
import Notification from '@/components/booking/auto-booking/Notification.vue'

@Component({
  components: { Notification },
})
export default class Step5 extends Vue {
  @Prop()
  private data: AutoBooking

  @Prop()
  private meta: AutoBookingMeta

  private error = ''
  private sortOrders = []
  private customerTypes = []

  public created(): void {
    this.sortOrders = [
      { id: 'Standard', name: this.$t('c:AutoBookingSortOrder:Standard') },
      { id: 'Random', name: this.$t('c:AutoBookingSortOrder:Random') },
      { id: 'AlphaNumericPositions', name: this.$t('c:AutoBookingSortOrder:Alpha numeric positions') },
    ]
    this.customerTypes = [
      { id: 'All', name: this.$t('c:CustomerType:All') },
      { id: 'Business', name: this.$t('c:CustomerType:Business') },
      { id: 'Person', name: this.$t('c:CustomerType:Person') },
    ]
  }

  private next() {
    this.error = ''
    if (!this.data.sortOrder) {
      this.error = 'Must select a sort order'
      return
    }
    if (!this.data.customerType) {
      this.error = 'Must select a customer type'
      return
    }
    if (this.data.maxNumberOfBookings && !('' + this.data.maxNumberOfBookings).match(/^\d+$/)) {
      this.error = 'Invalid max-number-of-bookings - must be a number'
      return
    }
    if (!this.validateNumberArrayText(this.data.exceptCustomerNumbers, 'except customer numbers')) {
      return
    }
    if (!this.validateNumberArrayText(this.data.exceptHotelIds, 'except hotel IDs')) {
      return
    }
    if (!this.validateLicenseplateArrayText(this.data.exceptLicenseplates, 'except plate numbers')) {
      return
    }
    if (this.data.onlyCarBrandId && !('' + this.data.onlyCarBrandId).match(/^\d+$/)) {
      this.error = 'Invalid value for only-car-brand-ID, should be ID of a brand, which is a number'
      return
    }
    this.$emit('next')
  }

  private validateNumberArrayText(numbers: string, fieldLabel: string) {
    let error = false
    if (!numbers) {
      numbers = ''
    }
    const lines = numbers.split('\n')
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim()
      if (!line) {
        continue
      }
      if (!line.match(/^\d+$/)) {
        error = true
        break
      }
    }
    if (error) {
      this.error = 'In "' + fieldLabel + '" you should enter only numbers, each one on a separate line'
      return false
    } else {
      return true
    }
  }

  private validateLicenseplateArrayText(numbers: string, fieldLabel: string) {
    let error = false
    if (!numbers) {
      numbers = ''
    }
    const lines = numbers.split('\n')
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim()
      if (!line) {
        continue
      }
      if (!line.match(/^[A-Za-z0-9]+$/)) {
        error = true
        break
      }
    }
    if (error) {
      this.error =
        'In "' +
        fieldLabel +
        '" each plate number should consist of only numbers and characters, each plate number on a separate line'
      return false
    } else {
      return true
    }
  }

  private back() {
    this.$emit('back')
  }
}
