import { vxm } from '@/store'
import { DateTime } from 'luxon'
import VueAxiosInterface from '@asmadsen/vue-axios/types/VueAxiosInterface'

export default class AutoBookingMeta {
  public hasLoadedCalendar = false
  public calendarId: number
  public calendars = []
  public portals = []
  public places = []
  public hotelProducts = []
  public services = []

  public get defaultName(): string {
    const dt = DateTime.now().toFormat('yyyy-MM-dd')
    return 'Auto booking - ' + dt
  }

  public load(axios: VueAxiosInterface): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loadCalendars(axios)
        .then(() => {
          this.loadHotelTypes(axios).then(() => {
            resolve()
          })
        })
        .catch(() => {
          reject()
        })
    })
  }

  private loadHotelTypes(axios) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v4/site/products/tyre-hotel?perPage=500&includeDeleted=1')
        .then((response) => {
          const result = []
          for (let i = 0; i < response.data.data.length; i++) {
            const p = response.data.data[i]
            if (p.status === 'Deleted' && p.usedCount === 0) {
              continue
            }
            result.push(p)
          }
          this.hotelProducts = result
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to load tyre hotel products')
          reject()
        })
    })
  }

  private loadServices(axios) {
    return new Promise((resolve, reject) => {
      const params = 'perPage=500&sortBy=name&forAutoBooking=1'
      axios
        .get('/v4/site/calendars/' + this.calendarId + '/services?' + params)
        .then((response) => {
          this.services = response.data.data
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to load booking services')
          reject()
        })
    })
  }

  private loadCalendars(axios) {
    this.hasLoadedCalendar = false
    return new Promise((resolve, reject) => {
      axios
        .get('/v4/site/calendars?perPage=500')
        .then((response) => {
          this.calendars = response.data.data
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to load calendars')
          reject()
        })
    })
  }

  public selectCalendar(calendarId: number, axios: VueAxiosInterface): Promise<void> {
    this.hasLoadedCalendar = false
    this.calendarId = calendarId
    return new Promise((resolve, reject) => {
      this.loadPortals(axios)
        .then(() => {
          this.loadPlaces(axios).then(() => {
            this.loadServices(axios).then(() => {
              this.hasLoadedCalendar = true
              resolve()
            })
          })
        })
        .catch(() => {
          reject()
        })
    })
  }

  private loadPortals(axios) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v4/site/calendars/' + this.calendarId + '/portals?perPage=500')
        .then((response) => {
          this.portals = response.data.data
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to load portals')
          reject()
        })
    })
  }

  private loadPlaces(axios) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v4/site/calendars/' + this.calendarId + '/places?perPage=500')
        .then((response) => {
          this.places = response.data.data
          resolve()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Failed to load places')
          reject()
        })
    })
  }
}
