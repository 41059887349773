































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import AutoBooking from '@/models/booking/AutoBooking'
import AutoBookingMeta from '@/models/booking/AutoBookingMeta'

@Component({
  components: {},
})
export default class Step1 extends Vue {
  @Prop()
  private data: AutoBooking

  @Prop()
  private meta: AutoBookingMeta

  private error = ''

  private next() {
    if (!this.data.calendarId) {
      this.error = 'Must select a calendar'
      return
    }
    if (!this.data.portalId) {
      this.error = 'Must select a portal'
      return
    }
    if (!this.data.placeId) {
      this.error = 'Must select a place'
      return
    }
    if (!this.data.season) {
      this.error = 'Must select a season'
      return
    }
    if (!this.data.name) {
      this.error = 'Must input a name'
      return
    }
    this.$emit('next')
  }
}
