

































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import AutoBooking, { AutoBookingPeriod } from '@/models/booking/AutoBooking'
import AutoBookingMeta from '@/models/booking/AutoBookingMeta'
import { DateTime } from 'luxon'

@Component({
  components: {},
})
export default class Step3 extends Vue {
  @Prop()
  private data: AutoBooking

  @Prop()
  private meta: AutoBookingMeta

  private error = ''

  private removePeriod(period: AutoBookingPeriod) {
    this.data.removePeriod(period)
  }

  private addPeriod() {
    this.data.addPeriod(new AutoBookingPeriod())
  }

  private get days() {
    const result = []
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    for (let i = 0; i < days.length; i++) {
      result.push({ id: i + 1, name: this.$t('c:day:' + days[i]) })
    }
    return result
  }

  private next() {
    this.error = ''
    if (this.data.periods.length === 0) {
      this.error = 'There must be at least one period that defines from-date and to-date'
      return
    }
    for (let i = 0; i < this.data.periods.length; i++) {
      const period = this.data.periods[i]
      if (!this.validatePeriod(i, period)) {
        return
      }
    }
    this.$emit('next')
  }

  private validatePeriod(i: number, period: AutoBookingPeriod) {
    const periodNumber = i + 1 + ''
    const nowDate = DateTime.now().toFormat('yyyy-MM-dd')
    if (!period.days || period.days.length === 0) {
      this.error = this.$t('Period %s must have at least one weekday selected (or it has no effect)').replace(
        '%s',
        periodNumber,
      )
      return false
    }
    if (!period.fromDate) {
      this.error = this.$t('Period %s must have a from-date').replace('%s', periodNumber)
      return false
    }
    if (!period.toDate) {
      this.error = this.$t('Period %s must have a to-date').replace('%s', periodNumber)
      return false
    }
    if (period.fromDate < nowDate) {
      this.error = this.$t('Period %s has from-date that is in the past').replace('%s', periodNumber)
      return false
    }
    if (period.toDate < nowDate) {
      this.error = this.$t('Period %s has to-date that is in the past').replace('%s', periodNumber)
      return false
    }
    if (!period.fromDate.match(/^\d\d\d\d-\d\d-\d\d$/)) {
      this.error = this.$t('Period %s from-date must be on format YYYY-MM-DD').replace('%s', periodNumber)
      return false
    }
    if (!period.toDate.match(/^\d\d\d\d-\d\d-\d\d$/)) {
      this.error = this.$t('Period %s to-date must be on format YYYY-MM-DD').replace('%s', periodNumber)
      return false
    }
    if (period.fromTime && !period.fromTime.match(/^\d\d:\d\d$/)) {
      this.error = this.$t('Period %s from-time must be on format HH:MM').replace('%s', periodNumber)
      return false
    }
    if (period.toTime && !period.toTime.match(/^\d\d:\d\d$/)) {
      this.error = this.$t('Period %s to-time must be on format HH:MM').replace('%s', periodNumber)
      return false
    }
    return true
  }

  private back() {
    this.$emit('back')
  }
}
