export class AutoBookingService {
  public id: number
  public autoBookingId: number
  public hotelProductId: number
  public serviceProductId: number
  public isEnabled: boolean

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.autoBookingId = data.autoBookingId ? (data.autoBookingId as number) : null
    this.hotelProductId = data.hotelProductId ? (data.hotelProductId as number) : null
    this.serviceProductId = data.serviceProductId ? (data.serviceProductId as number) : null
    this.isEnabled = data.isEnabled ? (data.isEnabled as boolean) : null
  }
}

export class AutoBookingPeriod {
  public id: number
  public index: number
  public autoBookingId: number
  public days: Array<number>
  public fromDate: string
  public toDate: string
  public fromTime: string
  public toTime: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.autoBookingId = data.autoBookingId ? (data.autoBookingId as number) : null
    this.days = data.days ? (data.days as Array<number>) : []
    this.fromDate = data.fromDate ? (data.fromDate as string) : ''
    this.toDate = data.toDate ? (data.toDate as string) : ''
    this.fromTime = data.fromTime ? (data.fromTime as string) : ''
    this.toTime = data.toTime ? (data.toTime as string) : ''
  }
}

export default class AutoBooking {
  public id: number
  public status: string
  public calendarId: number
  public portalId: number
  public placeId: number
  public season: string
  public name: string
  public requireConfirmation: boolean
  public step: number
  public services: Array<AutoBookingService>
  public periods: Array<AutoBookingPeriod>
  public contactMode: string
  public customerType: string
  public sortOrder: string
  public emailEnabled: boolean
  public emailSubject: string
  public emailBody: string
  public smsEnabled: boolean
  public smsBody: string
  public maxNumberOfBookings: number
  public ignoreCancelledBookings: boolean
  public exceptCustomerNumbers: string
  public exceptHotelIds: string
  public exceptLicenseplates: string
  public exceptProductIds: string
  public onlyProductIds: string
  public onlyCarBrandId: number
  public pauseAfterCreateBookings: boolean

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.portalId = data.portalId ? (data.portalId as number) : null
    this.placeId = data.placeId ? (data.placeId as number) : null
    this.season = data.season ? (data.season as string) : ''
    this.status = data.status ? (data.status as string) : ''
    this.name = data.name ? (data.name as string) : ''
    this.calendarId = data.portal ? ((data.portal as Record<string, unknown>).calendarId as number) : null
    this.requireConfirmation = (data.requireConfirmation as boolean) || false
    this.step = data.step ? (data.step as number) : 1
    this.services = []
    const services = data.services ? (data.services as Array<Record<string, unknown>>) : []
    for (let i = 0; i < services.length; i++) {
      this.services.push(new AutoBookingService(services[i]))
    }
    this.periods = []
    const periods = data.periods ? (data.periods as Array<Record<string, unknown>>) : []
    for (let i = 0; i < periods.length; i++) {
      const period = new AutoBookingPeriod(periods[i])
      period.index = i
      this.periods.push(period)
    }
    this.contactMode = data.contactMode ? (data.contactMode as string) : null
    this.customerType = data.customerType ? (data.customerType as string) : 'All'
    this.sortOrder = data.sortOrder ? (data.sortOrder as string) : 'Standard'
    this.emailEnabled = !!data.emailEnabled
    this.emailSubject = data.emailSubject ? (data.emailSubject as string) : ''
    this.emailBody = data.emailBody ? (data.emailBody as string) : ''
    this.smsEnabled = !!data.smsEnabled
    this.smsBody = data.smsBody ? (data.smsBody as string) : ''

    this.maxNumberOfBookings = data.maxNumberOfBookings ? (data.maxNumberOfBookings as number) : null
    this.ignoreCancelledBookings = data.ignoreCancelledBookings ? (data.ignoreCancelledBookings as boolean) : false
    this.exceptCustomerNumbers = data.exceptCustomerNumbers ? (data.exceptCustomerNumbers as string) : ''
    this.exceptHotelIds = data.exceptHotelIds ? (data.exceptHotelIds as string) : ''
    this.exceptLicenseplates = data.exceptLicenseplates ? (data.exceptLicenseplates as string) : ''
    this.exceptProductIds = data.exceptProductIds ? (data.exceptProductIds as string) : ''
    this.onlyProductIds = data.onlyProductIds ? (data.onlyProductIds as string) : ''
    this.onlyCarBrandId = data.onlyCarBrandId ? (data.onlyCarBrandId as number) : null
    this.pauseAfterCreateBookings = !!data.pauseAfterCreateBookings
  }

  public addPeriod(period: AutoBookingPeriod = null) {
    let max = 0
    for (let i = 0; i < this.periods.length; i++) {
      const index = this.periods[i].index || 0
      if (index > max) {
        max = index
      }
    }
    period.index = max + 1
    this.periods.push(period)
  }

  public removePeriod(period: AutoBookingPeriod) {
    const result = []
    for (let i = 0; i < this.periods.length; i++) {
      const index = this.periods[i].index || 0
      if (index === period.index) {
        continue
      }
      result.push(this.periods[i])
    }
    this.periods = result
  }
}
