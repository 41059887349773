























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import AutoBooking from '@/models/booking/AutoBooking'
import AutoBookingMeta from '@/models/booking/AutoBookingMeta'
import Notification from '@/components/booking/auto-booking/Notification.vue'
import { vxm } from '@/store'
import { DateTime } from 'luxon'

@Component({
  components: { Notification },
})
export default class Step6 extends Vue {
  @Prop()
  private data: AutoBooking

  @Prop()
  private meta: AutoBookingMeta

  @Prop()
  private active: boolean

  private previewCreatedAt: DateTime = null
  private pollInterval = 2000
  private shouldReGenerate = false
  private isPolling = true
  private isWarningVisible = false
  private timezone = 'UTC'
  private status = ''
  private error = ''
  private result = []
  private availableTimesByService = []
  private lastUpdatedTime = null
  private stats = {
    hotelCount: 0,
    bookingCount: 0,
    minTime: '',
    maxTime: '',
  }

  private periodStartDate = ''
  private periodEndDate = ''

  private search = ''
  private headers = []

  public created(): void {
    // window._store = this.$store
    this.headers = [
      {
        text: 'SortOrder',
        value: 'sortOrder',
      },
      {
        text: 'Hotel',
        value: 'carLicenseplate',
      },
      {
        text: 'Name',
        value: 'contactName',
      },
      {
        text: 'Mobile',
        value: 'contactMobile',
      },
      {
        text: 'Email',
        value: 'contactEmail',
      },
      {
        text: 'Wheel 1',
        value: 'wheel1',
      },
      {
        text: 'Studded',
        value: 'studded',
      },
      {
        text: 'HotelProduct',
        value: 'hotelProductId',
      },
      {
        text: 'BookingService',
        value: 'bookingServiceId',
      },
      {
        text: 'Booking',
        value: 'booking.time',
      },
    ]
  }

  public mounted(): void {
    this.loopPoll()
  }

  private get isReady(): boolean {
    return this.status === 'Created'
  }

  @Watch('active')
  private onActiveChange() {
    this.status = 'Checking'
  }

  private loopPoll() {
    if (this.active && this.status !== 'Created') {
      this.poll(() => {
        setTimeout(() => {
          this.loopPoll()
        }, this.pollInterval)
      })
    } else {
      setTimeout(() => {
        this.loopPoll()
      }, this.pollInterval)
    }
  }

  private reGenerate() {
    this.shouldReGenerate = true
    this.status = 'Creating'
  }

  private get lastUpdateTimeFormatted(): string {
    return this.lastUpdatedTime ? this.lastUpdatedTime.toFormat('HH:mm:ss') : this.$t('Never')
  }

  private poll(callback: () => void) {
    this.isPolling = true
    this.stats = {
      hotelCount: 0,
      bookingCount: 0,
      minTime: '',
      maxTime: '',
    }
    let refreshQuery = ''
    if (this.shouldReGenerate) {
      refreshQuery = '?refresh=1'
      this.shouldReGenerate = false
    }
    console.log('POLL: /v4/site/calendars/0/auto-bookings/' + this.data.id + '/booking-preview' + refreshQuery)
    this.$axios
      .get('/v4/site/calendars/0/auto-bookings/' + this.data.id + '/booking-preview' + refreshQuery)
      .then((response) => {
        this.error = ''
        this.status = response.data.data.status as string
        if (this.status === 'Created') {
          this.populate(response.data.data)
        }
        this.lastUpdatedTime = DateTime.now()
        this.isPolling = false
        callback()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error fetching preview')
        const msg = err.response?.data?.error?.message || 'Unknown error'
        this.error = 'Error fetching preview: ' + msg + ' (will try again)'
        this.isPolling = false
        callback()
      })
  }

  private populate(data: Record<string, unknown>) {
    this.timezone = data.timezone as string
    this.periodStartDate = data.periodStartDate as string
    this.periodEndDate = data.periodEndDate as string
    this.result = data.items as Array<Record<string, unknown>>
    this.availableTimesByService = (data.availableTimesByService || []) as Array<Record<string, unknown>>
    this.previewCreatedAt = DateTime.fromISO(data.createdAt as string, { zone: 'UTC' })
    for (let i = 0; i < this.result.length; i++) {
      this.stats.hotelCount++
      if (this.result[i].booking) {
        this.stats.bookingCount++
        if (this.stats.minTime === '' || this.result[i].booking.time < this.stats.minTime) {
          this.stats.minTime = this.result[i].booking.time
        }
        if (this.stats.maxTime === '' || this.result[i].booking.time > this.stats.maxTime) {
          this.stats.maxTime = this.result[i].booking.time
        }
      }
    }
  }

  private get previewAge(): string {
    if (!this.previewCreatedAt) {
      return '(unknown)'
    }
    const t = this.previewCreatedAt.toSeconds()
    const now = DateTime.now().toUTC().toSeconds()
    const age = now - t
    if (age > 60 * 60 * 2) {
      return Math.round(age / 60 / 60) + ' hours ago'
    } else if (age > 60) {
      return Math.round(age / 60) + ' minutes ago'
    } else {
      return Math.round(age) + ' seconds ago'
    }
  }

  private getDisplayTime(input: string): string {
    if (!input) {
      return ''
    }
    const dt = DateTime.fromISO(input, { zone: 'UTC' })
    return dt.setZone(this.timezone).toFormat('yyyy-MM-dd HH:mm')
  }

  private get minTimeDisplay(): string {
    return this.getDisplayTime(this.stats.minTime)
  }

  private get maxTimeDisplay(): string {
    return this.getDisplayTime(this.stats.maxTime)
  }

  private getAddonText(item, key) {
    let source = 'from none'
    if (item['contact' + key] === item['driver' + key] && item['contact' + key] === item['customer' + key]) {
      source = 'same for both'
    } else if (item['contact' + key] === item['driver' + key]) {
      source = 'from driver'
    } else if (item['contact' + key] === item['customer' + key]) {
      source = 'from customer'
    }
    return '(' + this.$t('c:autobooking:' + source) + ')'
  }

  private next() {
    this.isWarningVisible = false
    this.$emit('next')
  }

  private back() {
    this.$emit('back')
  }
}
